import { json, type LoaderFunctionArgs } from "@remix-run/node";
import type { MetaFunction } from "@remix-run/react";
import { useLoaderData } from "@remix-run/react";
import Blocks from "~/components/Blocks";
import Hero from "~/components/Hero";
import Pad from "~/components/layout/Pad";
import i18next from "~/i18next.server";
import { createTitle } from "~/util/createTitle";
import { createClient } from "~/graphql/createClient";
import PageQuery from "~/graphql/PageQuery";
import { nonNullable } from "~/util/nonNullable";
import { generateETag } from "~/util/cache-control/generateETag";
import { defaultHeaders } from "~/util/cache-control/defaultHeaders";
import { useLoaderHeadersAsRouteHeaders } from "~/util/useLoaderHeadersAsRouteHeaders";

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  // home page does not have a title
  const title =
    data?.page?.slug === "home"
      ? createTitle()
      : createTitle(data?.page?.title);
  return [
    {
      title,
    },
    {
      name: "description",
      content: data?.page?.meta?.description,
    },
    {
      property: "og:title",
      content: title,
    },
    {
      property: "og:description",
      content: data?.page?.meta?.description,
    },
    {
      property: "og:image",
      content: data?.page?.meta?.image?.url,
    },
  ];
};

export const loader = async ({
  request,
  params: { page },
}: LoaderFunctionArgs) => {
  const locale = await i18next.getLocale(request);
  const t = await i18next.getFixedT(locale);
  const res = await createClient(request).query(PageQuery, {
    slug: page ?? "home",
    locale,
  });

  if (res.error || !res.data) {
    throw json(t("ui.error.generic"), 500);
  }

  if (!res.data?.Pages?.docs?.length) {
    throw json(t("ui.error.pageNotFound"), 404);
  }

  const data = {
    page: res.data.Pages.docs[0],
    categories: res.data.Categories?.docs,
  };

  // ETag
  const eTag = generateETag(data);
  if (request.headers.get("If-None-Match") === eTag) {
    return new Response("", { status: 304 }) as unknown as typeof data;
  }

  return json(data, defaultHeaders({ eTag }));
};

export default function Page() {
  const { page, categories } = useLoaderData<typeof loader>();
  return page ? (
    <>
      {page?.showHero && <Hero categories={categories?.filter(nonNullable)} />}
      <Blocks blocks={page?.layout} />
      <Pad />
    </>
  ) : null;
}

export const headers = useLoaderHeadersAsRouteHeaders;
